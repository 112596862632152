import React from 'react';

const OpenForgottenPasswordModal = ({ openForgottenPasswordModal }) => (
  <button
    type="button"
    className="p-0 mb-30 c--black-blue fw-400 fs-14 td-u bg-none b-none "
    onClick={openForgottenPasswordModal}>
    Forgotten your password?
  </button>
);

export default OpenForgottenPasswordModal;
