import React from 'react';
import { Modal } from '@cityelectricalfactors/ui-components';
import styled from 'styled-components';
import { blackBlue85 } from '../../../../assets/stylesheets/v2/abstracts/_variables.scss';

const AccountModal = ({ modalIsOpen, closeModal, modalConfig }) => {
  const { heading, content, modalLinks } = modalConfig;

  return (
    <>
      <StyledModal
        key={heading}
        isOpen={modalIsOpen}
        closeModal={closeModal}
        className="account-modal__container">
        <h2 className="account-modal__header fl jc-c m-0 mb-20 ta-c fw-700 fs-32">
          {heading}
        </h2>
        {content}
        <div className="account-modal__links fl jc-c">{modalLinks}</div>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  > div {
    border-radius: 8px;
    padding: 40px;
    width: 90%;
    max-width: 400px;
    > h2 {
      color: ${blackBlue85};
    }
  }
`;

export default AccountModal;
