import { useState } from 'react';
import { emailRegex } from '../components/shared/EmailRegex';

const useModalForm = ({ initialFields, requestUrl, method, isRegister }) => {
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState({});
  const [requestConfig, setRequestConfig] = useState({
    url: null,
    method: '',
    headers: {
      Accept: 'application/json',
    },
  });

  const upperCaseFirst = string =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const validateInputs = () => {
    const newErrors = {};
    Object.keys(fields).forEach(field => {
      if (!fields[field]) {
        newErrors[field] = `${upperCaseFirst(field)} is required`;
      } else if (field === 'email' && !emailRegex.test(fields[field])) {
        newErrors[field] = `${upperCaseFirst(field)} is invalid`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = field => e => {
    setFields(prev => ({ ...prev, [field]: e.target.value }));
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      setRequestConfig({
        url: requestUrl,
        method,
        // Register does not use the user object, remove it if its register
        data: isRegister ? fields : { user: fields },
      });
    }
  };

  return {
    fields,
    errors,
    requestConfig,
    handleChange,
    handleSubmit,
    setRequestConfig,
  };
};

export default useModalForm;
