export const initialState = {
  activeModal: null, // No modal open initially
};

const modalReducer = (state, action) => {
  const actionMap = {
    OPEN_SIGN_IN: 'signIn',
    OPEN_REGISTER: 'register',
    OPEN_FORGOTTEN_PASSWORD: 'forgottenPassword',
    OPEN_RESEND_CONFIRMATION: 'resendConfirmation',
    CLOSE_MODAL: null,
    default: state, // Keeps the current modal if no match is found
  };
  return {
    ...state,
    activeModal: actionMap[action.type] || actionMap.default,
  };
};

export default modalReducer;
