import React, { useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useHTTP } from 'hooks/useRequest';
import FloatLabelInput from '../shared/FloatLabelInput';
import useModalForm from '../../hooks/useModalForm';

const ResendConfirmationContent = () => {
  const {
    errors,
    requestConfig,
    setRequestConfig,
    handleChange,
    handleSubmit,
  } = useModalForm({
    initialFields: { email: '' },
    requestUrl: '/users/confirmation',
    method: 'POST',
  });

  const { data, error } = useHTTP(requestConfig);

  useEffect(() => {
    setRequestConfig({ url: null, method: null });

    if (data) {
      // Refresh the page on success
      window.location.reload();
    }
  }, [data, error]);

  return (
    <>
      <div className="account-modal__fields fl fl-col jc-c">
        <FloatLabelInput
          type="email"
          label="Email Address"
          onChange={handleChange('email')}
          error={errors?.email}
        />
      </div>
      {error && (
        <p className="modal-submit__error mt-0 ta-c fs-14 c--red">
          {error?.error?.data?.error}
        </p>
      )}
      <div className="modal-actions fl fl-col jc-c ai-c">
        <Button
          className="btn-black-blue plr-10 mb-20 width-60"
          type="submit"
          handleClick={() => handleSubmit()}>
          Resend
        </Button>
      </div>
    </>
  );
};

export default ResendConfirmationContent;
