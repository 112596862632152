import React, { useState, useEffect } from 'react';
import { Button } from '@cityelectricalfactors/ui-components';
import { useHTTP } from 'hooks/useRequest';
import OpenForgottenPasswordModal from './shared/OpenForgottenPasswordModal';
import FloatLabelInput from '../shared/FloatLabelInput';
import useModalForm from '../../hooks/useModalForm';

const RegisterContent = ({ dispatch }) => {
  const {
    errors,
    requestConfig,
    setRequestConfig,
    handleChange,
    handleSubmit,
  } = useModalForm({
    initialFields: { name: '', email: '', telephone: '' },
    requestUrl: '/account_applications',
    method: 'POST',
    isRegister: true,
  });

  const { data, error } = useHTTP(requestConfig);
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    setRequestConfig({ url: null, method: null });

    if (data) {
      // Refresh the page on successful register
      window.location.reload();
    }

    if (error) {
      setResponseError(error.error.data.error);
    }
  }, [data, error]);

  return (
    <>
      <div className="account-modal__fields fl fl-col jc-c">
        <FloatLabelInput
          type="name"
          label="Name"
          onChange={handleChange('name')}
          error={errors?.name || responseError?.name}
        />
        <FloatLabelInput
          type="email"
          label="Email Address"
          onChange={handleChange('email')}
          error={errors?.email || responseError?.email}
        />
        <FloatLabelInput
          type="telephone"
          label="Telephone"
          onChange={handleChange('telephone')}
          error={errors?.telephone || responseError?.telephone}
        />
      </div>
      <OpenForgottenPasswordModal
        openForgottenPasswordModal={() =>
          dispatch({ type: 'OPEN_FORGOTTEN_PASSWORD' })
        }
      />
      <div className="modal-actions fl fl-col jc-c ai-c">
        <Button
          className="btn-black-blue plr-10 mb-20 width-60"
          type="submit"
          handleClick={() => handleSubmit()}>
          Register
        </Button>
      </div>
    </>
  );
};

export default RegisterContent;
