import React, { useReducer } from 'react';
import { AccountIconOutline } from 'components/shared/CommonSvgs';
import AccountModal from './shared/Modal';
import { modalContentConfig } from './shared/ModalContentConfig';
import modalReducer, { initialState } from './AccountModalsReducer';

const AccountModals = () => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  const currentModal = modalContentConfig(dispatch, state.activeModal);

  return (
    <>
      <button
        type="button"
        className="fl ai-c jc-c br-100 btn-header-grey plr-10 m-plr-20
                  ptb-12 m-mr-16 fw-400 fs-16 td-none"
        onClick={() => dispatch({ type: 'OPEN_SIGN_IN' })}>
        <AccountIconOutline width="24" height="24" />
        <span className="d-none m-fl ml-10 fs-16">Sign In</span>
      </button>
      {currentModal && (
        <AccountModal
          modalIsOpen={state.activeModal}
          closeModal={() => dispatch({ type: 'CLOSE_MODAL' })}
          modalConfig={currentModal}
        />
      )}
    </>
  );
};

export default AccountModals;
