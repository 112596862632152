import React from 'react';

const OpenSignInModal = ({ openSignInModal }) => (
  <>
    <span className="mr-10 fs-14">Already have an account? </span>
    <button
      type="button"
      className="p-0 c--black-blue fw-400 fs-14 td-u bg-none b-none"
      onClick={openSignInModal}>
      <span className="c--black-blue">Sign in</span>
    </button>
  </>
);
export default OpenSignInModal;
