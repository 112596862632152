import React, { useReducer } from 'react';
import AccountModal from '../AccountModals/shared/Modal';
import { modalContentConfig } from '../AccountModals/shared/ModalContentConfig';
import modalReducer, {
  initialState,
} from '../AccountModals/AccountModalsReducer';

const PricingInfoLinks = () => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  const currentModal = modalContentConfig(dispatch, state.activeModal);

  return (
    <>
      <h3>
        Pricing information is only available to registered trade customers.
      </h3>
      <ul>
        <li>
          If you have a Pik-a-Pak online account,
          <button
            type="button"
            className="m-0 p-0 bg-none b-none td-u fs-16 ff--r"
            onClick={() => dispatch({ type: 'OPEN_SIGN_IN' })}>
            please login to access the site.
          </button>
        </li>
        <li>
          If you have a Pik-a-Pak trade account,
          <a className="c--black" href="/users/sign_up">
            you can register here to use your account online.
          </a>
        </li>
        <li>
          If you do not have an account with Pik-a-Pak, and you are a trade
          customer,
          <button
            type="button"
            className="m-0 p-0 bg-none b-none td-u fs-16 ff--r"
            onClick={() => dispatch({ type: 'OPEN_REGISTER' })}>
            please contact us to open an account.
          </button>
        </li>
      </ul>
      {currentModal && (
        <AccountModal
          modalIsOpen={state.activeModal}
          closeModal={() => dispatch({ type: 'CLOSE_MODAL' })}
          modalConfig={currentModal}
        />
      )}
    </>
  );
};

export default PricingInfoLinks;
