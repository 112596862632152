import React from 'react';
import ForgottenPasswordContent from '../ForgottenPassword';
import ResendConfirmationContent from '../ResendConfirmation';
import RegisterContent from '../Register';
import SignInContent from '../SignIn';
import OpenSignInModal from './OpenSignInModal';

export const modalContentConfig = (dispatch, activeModal) => {
  const config = {
    signIn: {
      heading: 'Sign in',
      content: <SignInContent dispatch={dispatch} />,
      modalLinks: (
        <>
          <div className="fl">
            <button
              type="button"
              className="td-u bg-none b-none"
              onClick={() => dispatch({ type: 'OPEN_REGISTER' })}>
              Register Account
            </button>
            <div className="lr-divider" />
            <button
              type="button"
              className="td-u bg-none b-none"
              onClick={() => dispatch({ type: 'OPEN_RESEND_CONFIRMATION' })}>
              Activate Account
            </button>
          </div>
        </>
      ),
    },
    register: {
      heading: 'Account Application',
      content: <RegisterContent dispatch={dispatch} />,
      modalLinks: (
        <OpenSignInModal
          openSignInModal={() => dispatch({ type: 'OPEN_SIGN_IN' })}
        />
      ),
    },
    forgottenPassword: {
      heading: 'Forgotten Password',
      content: <ForgottenPasswordContent />,
      modalLinks: (
        <OpenSignInModal
          openSignInModal={() => dispatch({ type: 'OPEN_SIGN_IN' })}
        />
      ),
    },
    resendConfirmation: {
      heading: 'Resend Confirmation Instructions',
      content: <ResendConfirmationContent />,
      modalLinks: (
        <OpenSignInModal
          openSignInModal={() => dispatch({ type: 'OPEN_SIGN_IN' })}
        />
      ),
    },
  };

  return config[activeModal] || null;
};
