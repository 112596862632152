import React from 'react';

const FloatLabelInput = ({ type, label, value, error, onChange }) => (
  <div className="field-wrapper mb-16">
    <label
      className={`float-label__wrapper p-10 pl-6 pos-rel fl b-full b-colour--grey br-4
                  ${error ? ' input-error' : ''}`}
      htmlFor={`float-label-${type}`}>
      <input
        id={`float-label-${type}`}
        data-testid={type}
        className="float-label__input width-100 br-4 ff--body mt-10"
        type={type}
        value={value}
        onChange={onChange}
        placeholder=" "
      />
      <span className="pos-absolute c--grey50 fs-14">{label}</span>
    </label>
    <p className="p-0 m-0 mt-6 fs-12 c--red">{error}</p>
  </div>
);
export default FloatLabelInput;
